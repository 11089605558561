import React, {
  useEffect,
  Suspense,
  useState,
  createRef,
  forwardRef,
} from "react";
import { Vector3 } from "three";
import { Canvas } from "@react-three/fiber";
import {
  OrbitControls,
  Sphere,
  useTexture,
  Line,
  Html,
} from "@react-three/drei";
import {
  EffectComposer,
  DepthOfField,
  Noise,
} from "@react-three/postprocessing";
import ReactMarkdown from "react-markdown";
import { useSpring, useTrail, animated } from "react-spring";
import { Range, Direction, getTrackBackground } from "react-range";
import footnotes from "remark-footnotes";

// Scene Constants
var WIDTH = window.innerWidth;
var HEIGHT = window.innerHeight;

// Camera Settings
var VIEW_ANGLE = 45;
var ASPECT = WIDTH / HEIGHT;
var NEAR = 0.05;
var FAR = 30;

const Earth = forwardRef((props, ref) => {
  const map = useTexture("8192_earthmap10k_optimized.jpg");
  return (
    <>
      <Sphere ref={ref} args={[1, 52, 52]}>
        <meshBasicMaterial map={map} attach="material" />
      </Sphere>
    </>
  );
});

const calcPosFromLatLonRad = (lat, lon, radius) => {
  var phi = (90 - lat) * (Math.PI / 180);
  var theta = (lon + 180) * (Math.PI / 180);

  const x = -(radius * Math.sin(phi) * Math.cos(theta));
  const z = radius * Math.sin(phi) * Math.sin(theta);
  const y = radius * Math.cos(phi);

  return [x, y, z];
};

const Marker = ({ lat, lng, rad, text, occlude, index, selectTerm }) => {
  const [x, y, z] = calcPosFromLatLonRad(lat, lng, rad);
  const points = [];
  points.push(new Vector3(0, 0, 0));
  points.push(new Vector3(x, y, z));
  return (
    <>
      <Line points={points} color={"white"} lineWidth={0.4} />
      <Sphere args={[0.0025, 32, 32]} position={[x, y, z]}>
        <meshBasicMaterial color={"white"} />
      </Sphere>
      <Html distanceFactor={5} position={[x, y, z]} occlude={occlude}>
        <p
          className="markerText"
          onClick={() => {
            selectTerm(index);
          }}
        >
          {text}
        </p>
      </Html>
    </>
  );
};

const DateRange = ({ values, setValues }) => {
  const [MIN, MAX] = [1000, new Date().getFullYear()];
  return (
    <>
      <p style={{ color: "white" }}>{values[1]}</p>
      <Range
        step={5}
        min={MIN}
        max={MAX}
        values={values}
        direction={Direction.Up}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              margin: "10px 0px",
              flexGrow: 1,
              width: "2px",
              background: getTrackBackground({
                values,
                colors: ["#ccc", "green", "#ccc"],
                direction: Direction.Up,
                min: MIN,
                max: MAX,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "2px",
              width: "12px",
              backgroundColor: "#fff",
            }}
          />
        )}
      />
      <p style={{ color: "white" }}>{values[0]}</p>
    </>
  );
};

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    from: { opacity: 0 },
  });
  return (
    <>
      {trail.map(({ height, ...style }, index) => (
        <animated.div key={index} style={style}>
          <animated.div>{items[index]}</animated.div>
        </animated.div>
      ))}
    </>
  );
};

const App = () => {
  const [terms, setTerms] = useState([]);

  const [currentTerm, setCurrentTerm] = useState(false);
  const [dateValues, setDateValues] = useState([1200, 2020]);

  const [rangedTerms, setRangedTerms] = useState([]);

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const [lower, upper] = dateValues;
    // const currentYear = new Date().getFullYear();
    setRangedTerms(
      terms.filter((term) => {
        return (
          (upper === null || term.etymology.circa < upper) &&
          (term.etymology.till === null || term.etymology.till > lower)
        );
      })
    );
  }, [terms, dateValues]);

  const handleTermSelection = (index) => {
    setCurrentTerm(terms[index]);
  };

  const contentProps = useSpring({
    opacity: currentTerm !== false ? 1 : 0,
    marginTop: currentTerm !== false ? 0 : -100,
  });

  const navProps = useSpring({
    opacity: currentTerm === false ? 1 : 0,
    marginTop: currentTerm === false ? 0 : -100,
  });

  useEffect(() => {
    const fetchTerms = () => {
      fetch("data.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setTerms(data.terms);
        });
    };
    fetchTerms();
  }, []);

  const occluderRef = createRef();
  return (
    <>
      {currentTerm !== false ? (
        <animated.div className="entryWrapper" style={contentProps}>
          <div className="entry">
            <Trail open={currentTerm !== false}>
              <p style={{ fontSize: "120%", fontWeight: "bold" }}>
                {currentTerm.term}
              </p>
              <p>
                {currentTerm.etymology?.circa} –
                {currentTerm.etymology.inUse
                  ? " Present"
                  : ` ${currentTerm.etymology.till}`}
              </p>
              <p>Definitions</p>
              <ul className="definitions">
                {currentTerm.definitions.map((definition, index) => (
                  <li key={index}>
                    <ReactMarkdown children={definition} />
                  </li>
                ))}
              </ul>
              <p>Etymology</p>
              <div className="etymology">
                <ReactMarkdown
                  remarkPlugins={[footnotes]}
                  // renderers={renderers}
                  children={currentTerm.etymology?.description}
                />
              </div>
            </Trail>
          </div>
          <button
            onClick={() => {
              setCurrentTerm(false);
            }}
          >
            Close
          </button>
        </animated.div>
      ) : (
        <>
          <animated.div className="nav" style={navProps}>
            <p>The Geofinancial Lexicon</p>
          </animated.div>
          <animated.div className="info" style={navProps}>
            <button
              onClick={() => {
                setShowInfo(true);
              }}
            >
              <img className="info-icon" src="info.svg" alt=""></img>
              Information
            </button>
            <a href="https://abstract.supply" target="_blank" rel="noreferrer">
              <img className="info-icon" src="logo.svg" alt=""></img>
              Abstract Supply
            </a>
          </animated.div>
          {showInfo && (
            <div
              className="infoPanel"
              onClick={() => {
                setShowInfo(false);
              }}
            >
              <p style={{ fontSize: "120%", lineHeight: "50%" }}>
                The Geofinancial Lexicon
              </p>
              <p style={{ fontSize: "85%", lineHeight: "20%" }}>
                Ed. Jack Clarke & Sami Hammana
              </p>
              <p
                style={{
                  fontSize: "85%",
                  lineHeight: "20%",
                  marginBottom: "40px",
                }}
              >
                Published by{" "}
                <a href="https://abstract.supply">Abstract Supply</a>
              </p>
              <p>
                The Geofinancial Lexicon is a thought experiment centred on the
                literary convergence of earth- and financial-systems. It takes
                its cue from the large number of financial terms that reference
                phenomena of the natural world; protagonists such as ‘Bulls’,
                ‘Bears’, ‘Doves’, and ‘Hawks’, and phenomena such as the
                ‘iceberg order’, ‘dead cat bounce’ and ‘vampire squid’.
                Compiling definitions, and re-working the renowned financial
                lexicon of the Financial Times, the Geofinancial Lexicon
                welcomes the possibility to grasp the material and the abstract,
                as well as the so-called ‘natural’ and ‘human’ forces that shape
                the Anthropocene.
              </p>
              <p>
                This online 'twin' of the{" "}
                <a href="https://www.abstract.supply/books/geofinancial-lexicon">
                  physical publication
                </a>{" "}
                offers a free-to-access selection of words recorded in the
                lexicon and is regularly updated with new terminology. It is
                also used to house research conducted as part of the editors'
                ongoing 'geofinancial' investigations. If you would like to stay
                informed about the project and future work published here,
                please sign up to the{" "}
                <a href="https://tinyletter.com/AbstractSupply">
                  Abstract Supply newsletter
                </a>
                .
              </p>
              <p>
                If you are researching themes around the anthropocene and
                finance and would like to contribute an entry to this lexicon
                please <a href="mailto:info@abstract.supply">get in touch.</a>
              </p>
            </div>
          )}
        </>
      )}
      <div className="dateRange">
        <DateRange values={dateValues} setValues={setDateValues} />
      </div>
      <div style={{ height: "100vh", background: "black" }}>
        <Canvas
          camera={{
            fov: VIEW_ANGLE,
            aspect: ASPECT,
            near: NEAR,
            far: FAR,
          }}
        >
          <fog attach="fog" args={["darkblue", 1, 5]} />
          <Suspense fallback={null}>
            <OrbitControls
              enablePan={false}
              maxDistance={3.5}
              minDistance={1.25}
              autoRotate={currentTerm === false}
              autoRotateSpeed={0.2}
              enableDamping={true}
              rotateSpeed={0.4}
              zoomSpeed={0.5}
            />
            <Earth ref={occluderRef} />
            {rangedTerms.length > 0 &&
              rangedTerms.map((entry, index) => (
                <Marker
                  key={index}
                  lat={entry.location.lat}
                  lng={entry.location.lng}
                  rad={entry.location.rad}
                  text={entry.term}
                  occlude={[occluderRef]}
                  index={index}
                  selectTerm={handleTermSelection}
                />
              ))}
          </Suspense>
          <EffectComposer>
            <Noise opacity={0.05} />
            <DepthOfField
              focusDistance={0}
              focalLength={0.04}
              bokehScale={1}
              height={1000}
            />
          </EffectComposer>
        </Canvas>
      </div>
    </>
  );
};

export default App;
